import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import { RiArrowDropRightLine } from "react-icons/Ri"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPage = ({ data }) => (
  <Layout>
    <Seo title="プライバシーポリシー" />
    <ul className="page_list">
      <li>
        <Link className="page_link" to="/">
          Home
        </Link>
      </li>
      <li>
        <RiArrowDropRightLine className="page_arrow" />
      </li>
      <li>プライバシーポリシー</li>
    </ul>
    <div className="privacy_txtbox">
      <h1 className="privacy_title">プライバシーポリシー</h1>
      <p className="privacy_titles">privacy policy</p>
    </div>

    <div className="privacy_content">
      <p>
        株式会社インプル（以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）において取り扱う個人情報について、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定め、本ポリシーに従ってお客様の個人情報を取り扱います。
      </p>

      <h2>個人情報の取り扱いについて</h2>

      <h3>第1条（個人情報とは）</h3>
      <p>
        「個人情報」とは、当社が本サービスにおいてお客様からいただく、氏名、メールアドレス、電話番号等、特定の個人を識別できる情報（個人情報保護法第2条に規定されるものを指し、他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）を指します。
      </p>

      <h3>第2条（個人情報の収集方法）</h3>
      <p>
        当社は、お客様に個人情報のご提供をお願いするときは、予めその利用目的を明示いたします。お客様にご提供いただいた個人情報は、利用目的の範囲内で利用し、お客様の同意なく利用目的以外に利用いたしません。お客様が個人情報の提供をご希望されない場合、お客様ご自身の判断により、個人情報の提供を拒否することが可能です。この場合、本サービスをご利用いただけない場合がございますので、予めご了承ください。
      </p>

      <h3>第3条（個人情報を収集・利用する目的）</h3>
      <p>
        当社が個人情報を収集・利用する目的は、以下のとおりです。
        <br />
        1. 当社サービスの提供・運営のため
        <br />
        2. お客様からのお問い合わせに回答するため（本人確認を行うことを含む）
        <br />
        3.
        お客様が利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内を送付するため
        <br />
        4. 当社新サービスに関する営業上のご案内をするため
        <br />
        5.
        当社が行う利用動向調査に代表されるマーケティングリサーチ等の事業に活用するため
        <br />
        6. メンテナンス、重要なお知らせなど必要に応じたご連絡のため
        <br />
        7.
        ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため
        <br />
        8. 当社のサービスの解約や解約後の事後管理を行うため
        <br />
        9. その他、当社が個人情報の取得にあたり個別に通知した目的で利用するため
        <br />
        10. 上記各号の利用目的に付随する目的
      </p>

      <h3>第4条（利用目的の変更）</h3>
      <p>
        1.
        当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
        <br />
        2.
        利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、お客様に公表するものとします。
      </p>

      <h3>第5条（個人情報の第三者提供）</h3>
      <p>
        当社は、次に掲げる場合を除いて、予めお客様の同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
        <br />
        1.
        人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
        <br />
        2.
        個人情報に関する機密保持契約を締結している協力企業、提携企業及び業務委託企業に対して、お客様に明示した利用目的の達成に必要な範囲で個人情報の取り扱いを委託する場合（例：開発業務を業務委託企業へ委託する場合、代金決済時にクレジットカード会社へ照会を行う場合など）
        <br />
        3.
        統計的なデータとして、お客様個人を識別できない状態に加工した場合（例：本サービスにおける地域別利用状況を公表する場合など）
        <br />
        4.
        国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
        <br />
        5. 合併その他の事由による事業の承継に伴って個人情報が提供される場合
      </p>

      <h3>第6条（安全管理措置）</h3>
      <p>
        当社は、その取り扱う個人情報の漏えい、滅失または毀損の防止その他個人情報の安全管理のために、必要かつ適切な措置を講じます。当社が講じる安全管理措置については、「お問い合わせ窓口」までお問い合わせください。
      </p>

      <h3>第7条（個人情報の開示等）</h3>
      <p>
        お客様は、個人情報保護法において認められる範囲内において、利用目的の通知を求め、またはご提供いただいた個人情報もしくは第三者提供記録に関して開示を求めることができます。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
        <br />
        1.
        本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
        <br />
        2. 当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
        <br />
        3. その他法令に違反することとなる場合
      </p>

      <h3>第8条（個人情報の訂正、追加および削除）</h3>
      <p>
        {" "}
        1.
        お客様は、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。
        <br />
        2.
        当社は、お客様から前項の請求を受けた後、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報保護法において認められる範囲内において、遅滞なく、当該個人情報の訂正等を行うものとします。
        <br />
        3.
        当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをお客様に通知します。
      </p>

      <h3>第9条 （個人情報の利用停止等）</h3>
      <p>
        1.
        当社は、お客様から、個人情報が利用目的の範囲を超えて取り扱われているという理由、不正の手段により取得されたものであるという理由その他の個人情報保護法において認められる理由により、個人情報の利用の停止または消去もしくは第三者提供の停止（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
        <br />
        2.
        前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく当該個人情報の利用停止等を行います。
        <br />
        3.
        当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なくこれをお客様に通知します。
        <br />
        4.
        前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、お客様の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
      </p>

      <h3>第10条（手続）</h3>
      <p>
        当社は、お客様から、第7条乃至第9条に定める請求があったときは、所定の手続でご本人であることを確認の上、速やかに対応しますので、これらの請求の際は「お問い合わせ窓口」までご連絡ください。
      </p>

      <h3>第11条（プライバシーポリシーの変更）</h3>
      <p>
        1.
        本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、お客様に通知することなく変更することができるものとします。
        <br />
        2.
        当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
      </p>

      <h2>クッキー（cookie）ついて</h2>
      <p>
        当社では、お客様が本ウェブサイトをご利用された場合など、より便利に閲覧いただくために、お客様のウェブブラウザに対してクッキー（以下Cookie）を送信する場合があります。なお、Cookieはお客様個人を特定できる情報収集を行うことはありません。Cookieを停止したい場合は、ブラウザの設定で変更することが可能です。なお、広告主によるCookieの利用は、広告主のプライバシーの考えに従って行われます。
      </p>

      <h2>お問い合わせ窓口</h2>
      <p>本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。</p>
      <ul>
        {data.allStrapiHome.nodes.map(document => (
          <li key={document.id}>
            <p>{document.access}</p>
            <p className="company">{document.company} </p>
            <p>
              Skill-Repoプライバシーポリシー担当窓口　プライバシーポリシー担当
            </p>
            <p>TEL: {document.TEL}</p>
          </li>
        ))}
      </ul>
    </div>
  </Layout>
)

export default PrivacyPage

export const query = graphql`
  query {
    allStrapiHome {
      nodes {
        access
        TEL
        company
      }
    }
  }
`
